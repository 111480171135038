import React from 'react'
import '../../App.css'
export function Cards(props) {
  return (
    <>
      <a href={props.links.url}>
        <div className='linkImage'>
          <img src={props.links.img} alt="" />
          <p>{props.links.title}</p>
        </div>
      </a>

    </>
  )
}
