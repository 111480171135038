import React from 'react'
import { Cards } from './cards';
import { github, behance, sitePica, instagramers } from '../img/img.js';
import fropAvatar from '../img/fop.png'
import '../../App.css'
export function Links() {

    const link = [
        {
            img: behance,
            title: "Portfólio",
            url: "https://behance.net/frop",
        },
        {
            img: github,
            title: "Github",
            url: "https://github.com/imfrop",
        },
        {   
            img: sitePica,
            title: "Lagoa do Sapo",
            url: "https://frop.info",
        },
        {
            img: instagramers,
            title: "Instagram",
            url: "https://instagram.com/furoppoart",
        },
    ]

    
    return (
        <>
        <div className='container'>
            <img className='container-img'src={fropAvatar} alt='Luiz Frop Avatar'/>           
        </div>
        <div class='container-text'>
        <h2>Edward Aires</h2>  
        </div>
        <div>
            {link.map(link => {
                return(
                    <Cards links={link}/>
                )
            })}
            
        </div>
        </>
    )
}